@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?8be8c36c5fef0740d449a3714c02015a") format("truetype"),
url("./flaticon.woff?8be8c36c5fef0740d449a3714c02015a") format("woff"),
url("./flaticon.woff2?8be8c36c5fef0740d449a3714c02015a") format("woff2"),
url("./flaticon.eot?8be8c36c5fef0740d449a3714c02015a#iefix") format("embedded-opentype"),
url("./flaticon.svg?8be8c36c5fef0740d449a3714c02015a#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-living-room:before {
    content: "\f101";
}
.flaticon-crane:before {
    content: "\f102";
}
.flaticon-blueprint:before {
    content: "\f103";
}
.flaticon-graphic-design:before {
    content: "\f104";
}
.flaticon-customer-service:before {
    content: "\f105";
}
.flaticon-home:before {
    content: "\f106";
}
.flaticon-degrees:before {
    content: "\f107";
}
.flaticon-archery:before {
    content: "\f108";
}
.flaticon-competition:before {
    content: "\f109";
}
.flaticon-play-button:before {
    content: "\f10a";
}
